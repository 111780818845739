<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="合同编号" prop="contractNo">
                <!-- 公司简写字母—合同类型—日期—第几个合同.例如：YWKJ—XS—20201126001 采购 CG，销售XS，人事RS ，战略ZL，融资RZ。 -->
                <a-input v-model="queryParam.contractNo" placeholder="请输入合同编号" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="审批状态" prop="approvalStatus">
                <a-select
                  placeholder="请选择审批状态"
                  v-model="queryParam.approvalStatus"
                  style="width: 100%"
                  allow-clear
                >
                  <a-select-option
                    v-for="item in approvalStatusTypeOptions"
                    :key="item.dictValue"
                    :value="item.dictValue"
                  >
                    {{ item.dictLabel }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="合同名称" prop="contractName">
                  <a-input v-model="queryParam.contractName" placeholder="请输入合同名称" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="我方负责人及联系方式" prop="ourContactsPhone">
                  <a-input v-model="queryParam.ourContactsPhone" placeholder="请输入我方负责人及联系方式" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="对方负责人及联系方式" prop="partnerContactsPhone">
                  <a-input
                    v-model="queryParam.partnerContactsPhone"
                    placeholder="请输入对方负责人及联系方式"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="申请人" prop="createBy">
                  <a-input v-model="queryParam.createBy" placeholder="请输入申请人" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="创建时间">
                  <a-range-picker
                    style="width: 100%"
                    v-model="daterangeCreateTime"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <!-- <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:approvalContract:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['tenant:iot:approvalContract:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <a-button
          type="danger"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['tenant:iot:approvalContract:remove']"
        >
          <a-icon type="delete" />删除
        </a-button> -->
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:approvalContract:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :amountTypeOptions="amountTypeOptions"
        :invoiceTypeOptions="invoiceTypeOptions"
        :contractTypeOptions="contractTypeOptions"
        :effectiveOptions="effectiveOptions"
        :failureOptions="failureOptions"
        @ok="getList"
      />
      <approval-detail ref="approvalDetail" @ok="getList" />
      <div style="display: none">
        <p-contract
          @readyPrint="readyPrint"
          :id="contractId"
          :approvalStatusTypeOptions="approvalStatusTypeOptions"
          :amountTypeOptions="amountTypeOptions"
          :invoiceTypeOptions="invoiceTypeOptions"
          :contractTypeOptions="contractTypeOptions"
          :effectiveOptions="effectiveOptions"
          :failureOptions="failureOptions"
        ></p-contract>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <a-span slot="signingDate" slot-scope="text, record">
          {{ parseTime(record.signingDate) }}
        </a-span>
        <a-span slot="contractEffectiveDate" slot-scope="text, record">
          {{ parseTime(record.contractEffectiveDate) }}
        </a-span>
        <a-span slot="contractFailureDate" slot-scope="text, record">
          {{ parseTime(record.contractFailureDate) }}
        </a-span>
        <a-span slot="approvalStatus" slot-scope="text, record">
          {{ approvalStatusFormat(record) }}
        </a-span>
        <span slot="contractEffectiveMode" slot-scope="text, record">
          {{ effectiveFormat(record) }}
        </span>
        <span slot="contractFailureMode" slot-scope="text, record"> {{ failureFormat(record) }} </span
        ><span slot="contractType" slot-scope="text, record"> {{ contractTypeFormat(record) }} </span
        ><span slot="invoiceType" slot-scope="text, record"> {{ invoiceTypeFormat(record) }} </span
        ><span slot="amountType" slot-scope="text, record">
          {{ amountTypeFormat(record) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:approvalContract:edit']" />
          <a @click="handleView(record)" v-hasPermi="['tenant:iot:approvalContract:query']">
            <a-icon type="info-circle" />查看
          </a>
          <a v-hasPermi="['tenant:iot:approvalContract:print']" @click="toPrint(record)">
            <a-icon type="info-circle" />打印
          </a>
          <button style="display: none" ref="printBtn" v-print="printObj">x</button>
          <!-- <a-divider type="vertical" v-hasPermi="['tenant:iot:approvalContract:edit']" />
          <a
            @click="$refs.createForm.handleUpdate(record, undefined)"
            v-hasPermi="['tenant:iot:approvalContract:edit']"
          >
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:approvalContract:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:approvalContract:remove']">
            <a-icon type="delete" />删除
          </a> -->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listApprovalContract, delApprovalContract, exportApprovalContract } from '@/api/iot/approvalContract'
import CreateForm from './modules/CreateForm'
import ASpan from '@/components/ASpan'
import approvalDetail from '@/views/approval/approvalDetail'
import pContract from '@/views/printouts/contract.vue'

export default {
  name: 'ApprovalContract',
  components: {
    CreateForm,
    approvalDetail,
    ASpan,
    pContract
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 创建时间时间范围
      daterangeCreateTime: [],
      // 打印配置参数
      printObj: {
        id: 'contractPrint',
        popTitle: '合同审批',
        // extraCss:
        //   'https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        beforeOpenCallback(vue) {
          vue.printLoading = true
          console.log('打开之前')
        },
        openCallback(vue) {
          vue.printLoading = false
          console.log('执行了打印')
        },
        closeCallback(vue) {
          console.log('关闭了打印工具')
        }
      },
      // 查询参数
      queryParam: {
        contractNo: null,
        partnerContactsPhone: null,
        contractName: null,
        approvalStatus: undefined,
        createBy: null,
        createTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '合同编号',
          dataIndex: 'contractNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '合同名称',
          dataIndex: 'contractName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '审批状态',
          dataIndex: 'approvalStatus',
          scopedSlots: { customRender: 'approvalStatus' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申请人',
          dataIndex: 'createBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申请时间',
          dataIndex: 'createTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '我方联系人及联系方式',
          dataIndex: 'ourContactsPhone',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '对方负责人及联系方式',
          dataIndex: 'partnerContactsPhone',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      approvalStatusTypeOptions: [],
      amountTypeOptions: [],
      invoiceTypeOptions: [],
      contractTypeOptions: [],
      effectiveOptions: [],
      failureOptions: [],
      contractId: null
    }
  },
  filters: {},
  created() {
    this.getDictData()
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    async getDictData() {
      await this.getDicts('iot_approval_status_type').then((response) => {
        this.approvalStatusTypeOptions = response.data
      })
      await this.getDicts('iot_approvalContract_amountType').then((response) => {
        this.amountTypeOptions = response.data
      })
      await this.getDicts('iot_approvalContract_invoiceType').then((response) => {
        this.invoiceTypeOptions = response.data
      })
      await this.getDicts('iot_approvalContract_contractType').then((response) => {
        this.contractTypeOptions = response.data
      })
      await this.getDicts('iot_approvalContract_effectiveMode').then((response) => {
        this.effectiveOptions = response.data
      })
      await this.getDicts('iot_approvalContract_failureMode').then((response) => {
        this.failureOptions = response.data
      })
    },
    /** 查询合同审批列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeCreateTime !== null &&
        this.daterangeCreateTime !== '' &&
        this.daterangeCreateTime.length !== 0
      ) {
        this.queryParam.params['beginCreateTime'] = this.daterangeCreateTime[0]
        this.queryParam.params['endCreateTime'] = this.daterangeCreateTime[1]
      }
      listApprovalContract(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeCreateTime = []
      this.queryParam = {
        contractNo: undefined,
        partnerContactsPhone: undefined,
        contractName: undefined,
        approvalStatus: undefined,
        createBy: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    approvalStatusFormat(row, column) {
      return this.selectDictLabel(this.approvalStatusTypeOptions, row.approvalStatus)
    },
    amountTypeFormat(row, column) {
      return this.selectDictLabel(this.amountTypeOptions, row.amountType)
    },
    invoiceTypeFormat(row, column) {
      return this.selectDictLabel(this.invoiceTypeOptions, row.invoiceType)
    },
    contractTypeFormat(row, column) {
      return this.selectDictLabel(this.contractTypeOptions, row.contractType)
    },
    effectiveFormat(row, column) {
      return this.selectDictLabel(this.effectiveOptions, row.contractEffectiveMode)
    },
    failureFormat(row, column) {
      return this.selectDictLabel(this.failureOptions, row.contractFailureMode)
    },
    toPrint(record) {
      console.log('toPrint', record)
      this.contractId = record.id
      // this.$refs.printBtn.click()
    },
    readyPrint() {
      this.$refs.printBtn.click()
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delApprovalContract(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportApprovalContract(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    handleView(record) {
      console.log('record', record)
      const approvalRecord = {
        // 发起人
        promoter: record.applicantName,
        // 审批模块
        approvalKind: 'contract',
        // 费用记录ID
        approvalKindId: record.id,
        // 审批状态
        approvalStatus: record.approvalStatus,
        // 审批流程Key
        processInstanceId: record.processInstanceId
      }
      this.$refs.approvalDetail.handleDetail(approvalRecord)
    }
  }
}
</script>
